import _Vue from "vue";
import { stringifyNumber } from "@/utils/String";

export function capitalize(subject: unknown): string {
  if (typeof subject !== "string") {
    return String(subject);
  }

  const lower = subject.toLowerCase();

  return lower.charAt(0).toUpperCase() + lower.substr(1);
}

export function removeAccentedChars(subject: unknown): string {
  if (typeof subject !== "string") {
    return String(subject);
  }

  return subject.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function removeInitials(subject: unknown): string {
  if (typeof subject !== "string") {
    return String(subject);
  }

  return subject.replace(/[a-z]{1}\. /gi, "");
}

export function ellipsis(
  subject: unknown,
  nbWords = 6,
  trailing = "...",
  separator = " "
): string {
  if (typeof subject !== "string") {
    return String(subject);
  }

  let i = 0;
  let complete = false;
  let next = 0;
  while (i < nbWords && !complete) {
    next = subject.indexOf(separator, next + 1);

    if (next === -1) {
      complete = true;
    } else {
      i++;
    }
  }

  if (next <= 0) {
    return subject;
  }

  return subject.substring(0, next) + trailing;
}

export default {
  install(Vue: typeof _Vue, options: any) {
    Vue.mixin({
      filters: {
        capitalize,
        ellipsis,
        stringifyNumber
      }
    });
  }
};
