import amplitude from "amplitude-js";
import { authenticationPromise } from "@/services/Auth";
import { configuration } from "@/config/dynamic";
import { AMPLITUDE_VERSION_NAME } from "@/config/amplitude";

configuration.getInstance().then(config => {
  if (config.amplitudeKey) {
    amplitude.getInstance().init(config.amplitudeKey);

    authenticationPromise.getInstance().then(authentication => {
      authentication.onUserAvailable().then(({ user }) => {
        if (!user) {
          return;
        }

        //Amplitude built in user props
        amplitude.getInstance().setUserId(String(user.id));
        amplitude
          .getInstance()
          .setUserProperties({ user_type: user.category_code || "NA" });
        amplitude.getInstance().setVersionName(AMPLITUDE_VERSION_NAME || "0.1");
      });
    });
  }
});

export default amplitude.getInstance();
