import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import Comment from "@/models/Comment";

export function list(datasetId: number) {
  return $http
    .get(`${prefix}/datasets/${datasetId}/comments`)
    .then(response => responseToClass(Comment, response));
}

export function store(datasetId: number, data: number) {
  return $http
    .post(`${prefix}/datasets/${datasetId}/comments`, data)
    .then(response => responseToClass(Comment, response));
}

export function remove(datasetId: number, commentId: number) {
  return $http.delete(`${prefix}/datasets/${datasetId}/comments/${commentId}`);
}
