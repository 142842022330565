import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { paginatedResponseToClass, responseToClass } from "@/api/lib";
import User from "@/models/User";
import * as AccessCategory from "@/api/namespaces/user/accessCategory";
import * as Dataset from "@/api/namespaces/user/datasets";
import * as Formation from "@/api/namespaces/user/formation";
import * as Invitation from "@/api/namespaces/user/invitation";
import * as Organization from "@/api/namespaces/user/organization";
import * as Project from "@/api/namespaces/user/project";
import * as Task from "@/api/namespaces/user/task";
import * as Team from "@/api/namespaces/user/team";
import * as CubeJs from "@/api/namespaces/user/cubeJs";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import UpdateFormRequest from "@/api/requests/user/UpdateFormRequest";
import PaginationResponse from "@/interfaces/responses/PaginationResponse";
import { PaginationParams } from "@/interfaces/api/PaginationParams";
import FileModel from "@/models/File";

export {
  AccessCategory,
  Dataset,
  Formation,
  Invitation,
  Organization,
  Project,
  Task,
  Team,
  CubeJs,
};

export function show(userId: number): AxiosPromise<User> {
  return $http
    .get(`${prefix}/users/${userId}`)
    .then((response) => responseToClass(User, response));
}

export function update(
  userId: number,
  form: UpdateFormRequest
): AxiosPromise<User> {
  const formData = form.toFormData({
    indices: true,
    nullsAsUndefineds: false,
    booleansAsIntegers: true,
  });

  formData.append("_method", "PUT");

  return $http
    .post(`${prefix}/users/${userId}`, formData)
    .then((response) => responseToClass(User, response));
}

export function networkSearch(
  userId: number,
  query: string,
  { page }: PaginationParams
): AxiosPromise<PaginationResponse<User[]>> {
  return $http
    .get(`${prefix}/users/${userId}/network-search`, {
      params: { query, page },
    })
    .then((response) => paginatedResponseToClass(User, response));
}

export function upload(
  userId: number,
  data: FormData
): AxiosPromise<PaginationResponse<User[]>> {
  return $http
    .post(`${prefix}/users/${userId}/resume`, data)
    .then((response) => paginatedResponseToClass(User, response));
}

export function download(
  userId: number,
  resumeId: number
): AxiosPromise<PaginationResponse<User[]>> {
  return $http
    .get(`${prefix}/users/${userId}/resume/${resumeId}`)
    .then((response) => paginatedResponseToClass(User, response));
}

export function temporaryFile(
  userId: number,
  data: FormData,
  config: AxiosRequestConfig = {}
): AxiosPromise<FileModel> {
  return $http
    .post(`${prefix}/users/${userId}/temporary-files`, data, config)
    .then((response) => responseToClass(FileModel, response));
}

export function showTemporaryFile(
  userId: number,
  fileId: number
): AxiosPromise<FileModel> {
  return $http
    .get(`${prefix}/users/${userId}/temporary-files/${fileId}`)
    .then((response) => responseToClass(FileModel, response));
}

export function showProfilePhoto(userId: number): AxiosPromise<FileModel> {
  return $http
    .get(`${prefix}/users/${userId}/profile-photo`)
    .then((response) => responseToClass(FileModel, response));
}
