export default interface IModel {
  id: number;
}

export function isModelObject(object: unknown): object is IModel {
  if (typeof object !== "object" || object === null) {
    return false;
  }

  return "id" in object;
}
