import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { DATETIME_FORMAT } from "@/config/date";
import moment from "moment";
import store from "@/store";
import { apiUrl } from "@/config/app";
import amplitude from "amplitude-js";
import router from "@/router";
import { authenticationPromise } from "@/services/Auth";

const http = axios.create({
  baseURL: apiUrl.getInstance() + "/"
});

const exceptions: Array<string | RegExp> = [
  "/api/auth/login",
  "/api/auth/refresh",
  "/api/auth/recovery",
  "/api/auth/signup",
  "/api/auth/reset",
  "/api/license-agreements/latest",
  "/api/auth/change-password",
  "/api/v2/users/realm",
  new RegExp("/api/invitations/[a-f0-9]+"),
  new RegExp("/api/invitations/[a-f0-9]+/consume"),
  new RegExp("/api/users/[0-9]+/unsubscribe-notifications")
];

http.interceptors.request.use((config: AxiosRequestConfig) => {
  const headers: Record<string, string> = config.headers || {};
  const sessionId = amplitude.getInstance().getSessionId();

  if (sessionId) {
    headers["Amplitude-Session-Id"] = String(sessionId);
  }

  config.headers = headers;

  return config;
});

http.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (config.data instanceof File) {
      return config;
    }

    for (const key in config.data) {
      const param = config.data[key];
      if (param instanceof Date || param instanceof moment) {
        config.data[key] = moment(param).format(DATETIME_FORMAT);
      }
    }

    return config;
  }
);

http.interceptors.request.use((config: AxiosRequestConfig) => {
  const headers: Record<string, string> = config.headers || {};

  if (!config.url) {
    return config;
  }

  let route = config.url;
  try {
    const url = new URL(config.url);

    const params = url.searchParams;
    if (params.has("X-Amz-Signature")) {
      return config;
    }

    route = url.pathname;
  } catch (e) {
    // fallback to config.url
  }

  const isExcluded = exceptions.some(excludedRoute => {
    if (typeof excludedRoute === "string") {
      return excludedRoute === route;
    } else {
      return excludedRoute.test(route);
    }
  });

  if (isExcluded) {
    return config;
  }

  const token = (config.data && config.data["token"]) || false;
  if (token) {
    headers.Authorization = `Bearer ${token}`;

    config.headers = headers;

    return config;
  }

  return authenticationPromise.getInstance().then(authentication =>
    authentication.onReady().then(
      ({ token }) => {
        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }

        config.headers = headers;

        return config;
      },
      () => {
        return config;
      }
    )
  );
});

http.interceptors.response.use(
  (response: AxiosResponse) => {
    const route = router.currentRoute;

    if (response.status === 401) {
      const appState = {
        targetUrl: route.fullPath
      };

      store.dispatch(`user/logWithRedirect`, { appState });
    }

    return response;
  },
  error => Promise.reject(error)
);

export default http;
